import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Pagination component is a user interface element commonly used in web and application design to break down long lists or content into multiple pages.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pagination/pagination-guideline-anatomy.png",
      "alt": "anatomy button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Page Numbers`}</strong>{`: Numeric links that represent individual pages.`}</li>
      <li parentName="ol"><strong parentName="li">{`Navigation Buttons`}</strong>{`: Controls for moving to the previous or next page.`}</li>
      <li parentName="ol"><strong parentName="li">{`Ellipsis(Optional)`}</strong>{`: ellipsis (…) to indicate additional, unlisted page numbers.`}</li>
      <li parentName="ol"><strong parentName="li">{`Page Count(Optional)`}</strong>{`: An optional display of the total number of pages.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Pagination component is used in web and application design to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Divide Content`}</strong>{`: Split long content or data into manageable pages, improving readability and usability.`}</li>
      <li parentName="ul"><strong parentName="li">{`Navigate Data`}</strong>{`: Allow users to move between different sections or pages of content, such as search results or product listings.`}</li>
      <li parentName="ul"><strong parentName="li">{`Enhance User Experience`}</strong>{`: Provide an organized and efficient way for users to access information without overwhelming them.`}</li>
    </ul>
    <p><strong parentName="p">{`Common places pagination appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        You have content that extends beyond a single screen or viewable area.
      </li>
      <li>You want to break content into smaller, more digestible sections.</li>
      <li>
        Users need to navigate sequentially through multiple pages of content.
      </li>
      <li>Improving navigation and user experience is a priority</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        Your content or data is short and can be presented on a single page.
      </li>
      <li>Pagination would complicate the user interface unnecessarily.</li>
      <li>
        Other navigation methods, like infinite scrolling, are more suitable for
        your design.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Pagination components should be accessible to all users, including those with disabilities. To ensure accessibility, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that all elements of the pagination component are keyboard accessible.`}</li>
      <li parentName="ul">{`Use clear and concise link text.`}</li>
      <li parentName="ul">{`Add an aria-label attribute to the pagination component to provide a descriptive label for screen reader users.`}</li>
      <li parentName="ul">{`Use the aria-current=“page” attribute to indicate the current page in the pagination component to screen reader users.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Make sure that the current page number is highlighted. So the users know and can navigate easily." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t highlighted the current page number, so it can make a confusion for the users." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When implementing pagination content, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Keep page numbers and labels concise and informative.`}</li>
      <li parentName="ul">{`Display the current page number and indicate the total number of pages if relevant.`}</li>
      <li parentName="ul">{`Ensure that users can easily identify the first and last pages for efficient navigation.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Provide previous and next buttons. So users can easily navigate through out all the pages of the website." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t Provide previous and next buttons. So users can’t navigate through out all the pages of the website." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      